.sponsors h1{
    font-size: 3rem;
}

.slider{
    height: 250px;
    margin: auto;
    position: relative;
    width: 90%;
    display: grid;
    place-items: center;
    overflow: hidden;
}

.slide-track{
    display: flex;
    width: calc(250px * 12); /* slide track = total number of slides (3*2=6 * individual side width (250px) */
    animation: scroll 40s linear infinite;
}

.slide-track::hover {
    animation-play-state: paused;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-250px * 6));
    }
}

.slide{
    height: 200px;
    width: 250px;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
    object-fit: fill;
}

img {
    width: 100%;
    transition: transform 1s;
}

img:hover{
    transform: translateZ(20px);
}

/* Gradient shadows */

.slider::before,
.slider::after{
    background: linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    content: '';
    height: 100%;
    position: absolute;
    width: 15%;
    z-index: 2;
}

.slider::before{
    left: 0;
    top: 0;
}

.slider::after{
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}