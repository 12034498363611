@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.App{
    font-family: sans-serif;
    text-align: center;
}