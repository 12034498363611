.camps{
    margin: 2rem 6rem;
    color: black;
}

.camps h1{
    font-size: 3rem;
}

.camps h2{
    margin: 1rem;
    display: inline-block;
    position: relative; 
    /* border-bottom:1px solid #F5C908;
    padding-bottom:3px; */
}

.camps h2:before,
.camps h2:after {
    content: "";
    position: absolute;
    height: 6px;
    border-bottom: 3px solid #F5C908;
    border-top: 3px solid #F5C908;
    top: 0;
    width: 100px;
}
.camps h2:before {
    right: 100%;
    margin:1rem
}
.camps h2:after {
    left: 100%;
    margin: 1rem;
}

.campscard{
    margin-top: 1rem;
    display: block;
    justify-content: center;
}

.ongoing, .ongoing1 {
    display: flex;
    justify-content: center;
}

.finished{
    display: flex;
    justify-content: center;
}

.c-card{
    width: 32%; /* if 3 32$ */
    height: auto;
    text-align: start;
    box-shadow: 0 5px 25px 2px /* add color if needed */;
    border-radius: 10px;
    padding: 1rem .8rem;
    position: relative;
    margin: 10px;
}

.c-card img{
    transition: transform 0.5s;
    border-radius: 10px;
    object-fit: scale-down;
}

.layer{
    width: 100%;
    height: 0%;
    background: linear-gradient(rgba(0,0,0,.5), #F5C908);
    border-radius: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    pad: 0 40px;
    text-align: center;
    font-size: 14px;
    transition: 0.5s;
}

.layer h4{
    color: #E5E5E5;
    font-size: 60px;
    font-weight: bold;
}

.layer p{
    color: #E5E5E5;
    font-size: 35px;
}

.c-card:hover img{
    transform: scale(1.02);
    border-radius: 10px;
}

.c-card:hover .layer{
    height: 100%;
}

@media screen and (max-width: 850px) {
    .camps{
        margin: auto;
    }

    .c-card{
        width: 80%;
        padding: 0.5rem 0.5rem;
        margin: 1rem auto;
        justify-content: center;
    }

    .ongoing,
    .ongoing1,
    .finished {
        display: block;
        justify-content: center;
    }

    .camps h2:before,
    .camps h2:after {
        width: 50px;
    }
    
    
}