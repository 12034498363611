.NavbarItems{
    background-color: #E5E5E5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    /* box-shadow: 0 5px 15px 0 #E5E5E5; */
    width: 95%;
    height: 90px;
    border-radius: 13px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 99;
}

/* .navbar-logo-text{
    color: black;
    font-size: 2rem;
    cursor: pointer;
} */

.navbar-logo{
    height: 160px;
    width: auto;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(6,auto);
    grid-gap: 30px;
    list-style: none;
    align-items: center;
}

.nav-links{
    text-decoration: none;
    color: #000000;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.nav-links i{
    padding-right: 10px;
}

.nav-links:hover{
    background-color: #F5C908;
    color: #E5E5E5;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.menu-icons{
    display: none;
}

/* burger menu for mobile */
@media screen and (max-width: 850px) {
    .NavbarItems{
        z-index: 99;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #E5E5E5;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        transition: 0.3s ease-in-out;
        border-radius: 13px;
    }

    .nav-menu.active{
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;
    }

    .nav-links{
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 0.7rem 0;
    }

    .nav-links:hover{
        background-color: #F5C908;
        transition: none;
    }

    .menu-icons{
        display: block;
        cursor: pointer;
    }

    .menu-icons i{
        font-size: 2rem;
        color: #000000;
    }
}