.contact {
    margin: 4rem 6rem;
    display: flex;
    justify-content: space-between;
}

.contact-container {
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 48%; /* if 3 32$ */
    text-align: start;
    box-shadow: 0 5px 25px 2px /* add color if needed */;
    border-radius: 7px;
    padding: 1rem .8rem;
}

.contact-details{
    margin-top: 15%;
    margin-left: 5%;
}

.contact-details h3{
    font-size: 1.8rem;
    margin-bottom: 2rem;
}

.google-maps{
    width: 60%;
    height: auto;
    border-radius: 7px;
    margin-top: 2rem;
}

.contact-i{
    width: 60%;
    height: auto;
    border-radius: 7px;
    margin-top: 2rem;
}

@media screen and (max-width: 850px) {
    .contact{
        margin: .6rem .6rem;
        flex-direction: column;
        justify-content: space-around;
        width:fit-content;
    }

    .contact-container {
        width: fit-content;
        margin: 0.5rem;
    }

    .contact-details h3{
        font-size: 1rem;
    }

    .contact-details p{
        font-size: 0.7rem;
    }

    .contact-details:first-child {
        padding-bottom: 2rem;
    }
    
}