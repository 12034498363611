.footer {
    padding: 1rem 6rem;
    background: black;
    color: white;
}

.top {
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.top-left{
    display: flex;
    align-items: center;
}

.top img{
    height: 8rem;
    width: auto;
}

.top h1{
    margin-left: 1rem;
}

.top i{
    color: white;
    font-size: 2rem;
    margin-left: 1rem;
}

.top i:hover {
    color: #F5C908;
}

.bottom{
    text-align: center;
}

.bottom p{
    font-style: italic;
}

@media screen and (max-width: 850px) {
    .footer{
        padding: 1rem 2rem;
    }

    .footer i{
        margin: 0 2rem;
    }

    .top-right{
        width: 100%;
    }

    .bottom{
        padding-top: 1rem;
    }
}